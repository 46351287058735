import React from "react";
import Helmet from "react-helmet";
import { siteTitle, siteUrl, siteDescription, author } from '../../../config'

const SEO_DATA = {
  description: siteDescription,
  title: siteTitle,
  url: siteUrl,
  author: author,
  keywords: ["devops"],
};

const SEO = ({ title, description, isBlog, slug, noIndex }: SEOProps) => {
  return (
    <Helmet>
      <meta property="og:type" content={isBlog ? "article" : "website"} />
      <meta property="og:url" content={`${SEO_DATA.url}${slug ? slug : ''}`} />
      <meta property="og:description" content={description ? description : SEO_DATA.description} />
      {noIndex && <meta name="robots" content="noindex"></meta>}
      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(", ")} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{title ? title : SEO_DATA.title}</title>
      <html lang="de" />
    </Helmet>
  );
};

interface SEOProps {
  title?: string;
  description?: string,
  isBlog?: boolean;
  slug?: string;
  noIndex?: boolean;
}

export default SEO;

